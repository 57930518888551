.list-group {
  max-height: 270px;

  &-item {
    height: 90px;
    cursor: pointer;
  }

  &.shown {
    display: block;
  }
  &.hidden {
    display: none;
  }
}

@media (max-width: 768px) {
  .list-group {
    max-height: 180px;
  }
}
