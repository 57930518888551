.BookCalendar {
  height: calc(600px - 42px);
  width: 100%;
  box-sizing: border-box;
  padding-top: 110px;
  .arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.12);
    cursor: pointer;
    position: fixed;
    bottom: 55px;
    z-index: 20;
    background-color: #fff;
    &.left {
      left: 20px;
    }
    &.right {
      right: 20px;
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h2 {
      text-align: center;
      font-weight: 600;
      font-size: 16px;
      color: #253342;
      padding: 20px;
      margin: 0;
    }
  }

  .week {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    background: #f5f8fa;
    animation: slide-up2-fade-in ease 0.5s;
    animation-iteration-count: 1;
    transform-origin: 50% 50%;
    animation-fill-mode: forwards; /*when the spec is finished*/
    -webkit-animation: slide-up2-fade-in ease 0.5s;
    -webkit-animation-iteration-count: 1;
    -webkit-transform-origin: 50% 50%;
    -webkit-animation-fill-mode: forwards; /*Chrome 16+, Safari 4+*/
    -moz-animation: slide-up2-fade-in ease 0.5s;
    -moz-animation-iteration-count: 1;
    -moz-transform-origin: 50% 50%;
    -moz-animation-fill-mode: forwards; /*FF 5+*/
    -o-animation: slide-up2-fade-in ease 0.5s;
    -o-animation-iteration-count: 1;
    -o-transform-origin: 50% 50%;
    -o-animation-fill-mode: forwards; /*Not implemented yet*/
    -ms-animation: slide-up2-fade-in ease 0.5s;
    -ms-animation-iteration-count: 1;
    -ms-transform-origin: 50% 50%;
    -ms-animation-fill-mode: forwards; /*IE 10+*/
    opacity: 0;
    opacity: 1\9;

    .Day {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 385px;
      border-left: 1px solid #eaf0f6;
      background: #f5f8fa;
      overflow: scroll;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */

      &::-webkit-scrollbar {
        display: none;
      }

      &:first-of-type {
        border-left: none;
      }

      p {
        margin: 0;
      }

      .title {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 68px !important;
        color: #516f90;
        margin-bottom: 12px;
        background: #ffffff;
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);

        span {
          font-weight: normal;
          font-size: 14px;
          line-height: 17px;
          text-align: center;
          color: #516f90;
          margin-bottom: 6px;
        }

        p {
          font-size: 17px;
          font-weight: 500;
          color: #516f90;
        }
      }

      .slots {
        height: 300px;
        overflow: scroll;
        padding-bottom: 70px;
        width: 100%;

        .available {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 90%;
          height: 40px !important;
          border-radius: 6px;
          border: 1px #dedede solid;
          margin: 6px 0;
          font-size: 14px;
          transition: 0.2s;
        }

        .selectedDate {
          background-color: #7ab230 !important;
          border: 1px solid #ffffff;
          box-shadow: 0px 3px 4px 0px #00000033;
          color: white;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .BookCalendar {
    .week {
      .Day {
        height: calc(100vh - 200px);
        .title {
          width: 100% !important;
          height: 100px !important;
        }
        .slots {
          padding-bottom: 130px;
        }
      }
    }
  }
}
