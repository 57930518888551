.Header {
  height: 5%;
  display: flex;
  justify-content: space-between;
  width: 100%;
  box-shadow: 0px 4px 20px 0px #00000026;
  // display: flex;
  align-items: center;

  .logo-wraper {
    height: 100%;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media only screen and (min-width: 768px) {
  .Header {
    // height: 10% !important;
    box-shadow: none;

    img {
      display: none;
    }
  }
}

@media only screen and (max-width: 768px) {
  .Header {
    height: 10% !important;
  }
}
