.OrderForm {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
}

@media only screen and (min-width: 768px) {
  .OrderForm {
    &__content {
      height: 90%;

      .step_layer {
        .title_line {
          border: 2px solid #e5e5e5;
          width: 30%;
          margin: auto;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .OrderForm {
    &__content {
      height: 90%;

      .step_layer {
        min-height: 85% !important;

        .title_line {
          border: 2px solid #e5e5e5;
          width: 30%;
          margin: auto;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .OrderForm {
    &__content {
      height: 90%;
    }
  }
}
