.NextCategoryButton {
  display: flex;
  margin: auto;
  width: 100%;
  height: 45px;
  border: 1px solid black;
  border-radius: 3px;

  &:hover {
    cursor: pointer;
  }
  .section1 {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    width: 85%;
    background-color: #5d5d5d;
    text-align: center;
  }

  .section2 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15%;
    background-color: #464646;
  }

  &.large {
    width: 100%;
  }
}

.Submit_button {
  width: 100%;
  background-color: #6fbc0c;
  color: white;
  border: 1px solid #ffffff;
  border-radius: 3px;
  box-shadow: 0px 3px 5px 2px #0000001a;

  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  cursor: pointer;
}
