.Stepper {
  width: 100%;

  .container {
    padding: 0;
  }
  .progressbar {
    height: 100%;
    display: flex;
    padding: 0;
    align-items: center;
    justify-content: center;
    counter-reset: step;
    list-style-type: none;
    margin: 0;
  }
  .progressbar li {
    float: left;
    width: 20%;
    position: relative;
    text-align: center;
    font-size: 18px;
  }
  .progressbar li::before {
    content: counter(step);
    counter-increment: step;
    width: 30px;
    height: 30px;
    display: block;
    margin: 0 auto 10px auto;
    border-radius: 50%;
    line-height: 26px;
    background: #5a5a5a;
    color: white;
    text-align: center;
    border: 2px solid #ffffff;
    box-shadow: 0px 3px 5px 2px #0000001a;
  }

  .progressbar li:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 3px;
    background: linear-gradient(0deg, #b5b5b5, #b5b5b5),
      linear-gradient(0deg, #d3d3d3, #d3d3d3);
    top: 15px;
    left: -50%;
    z-index: -1;
  }

  .progressbar li:not(.additionall).active:before {
    border-color: #98e733;
    background: #98e733;
    color: white;
    border: 2px solid #ffffff;
    box-shadow: 0px 3px 5px 2px #0000001a;
  }

  .progressbar li:last-child.active:before {
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 0px;
    content: url("../../../assets/images/Vector.png");
  }

  .progressbar li:not(.additionall):last-child.active:before {
    content: url("../../../assets/images/Vector-white.png");
  }

  .progressbar li.active:after {
    background: #98e733;
  }

  .progressbar li:first-child:after {
    content: none;
  }
}
