.Step1 {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  .row {
    .chooseCategory {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: "0";

      .step_title {
        p {
          font-size: 20px;
          font-weight: 600;
        }
      }

      .buttons-container {
        width: 100%;

        .category-button {
          margin: auto;
          height: 49.65px;
          border: 1px solid#E2E2E2;
          border-radius: 3px;
          display: flex;
          align-items: center;
          margin-top: 20px;
          cursor: pointer;

          &.active {
            background-color: #9f9f9f;
            color: white;
          }

          img {
            position: absolute;
            margin-left: 20px;
          }

          &__text {
            width: 100%;
            text-align: center;

            p {
              margin: auto;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .category-button {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) {
  .category-button {
    width: 100%;
  }
}

@media only screen and (max-width: 320px) {
  .Step1 {
    .row {
      .chooseCategory {
        .step_title {
          p {
            font-size: 16px;
            // font-weight: 600;
          }
        }
      }
    }
  }
}
