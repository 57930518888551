.Footer {
  background-color: #c4c4c4;
  height: 5%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 600;
  position: fixed;
  bottom: 0;
  width: 100%;

  &__title {
    margin: 0;
    padding: 0;
  }

  button {
    position: fixed;
    right: 0px;
    bottom: 0px;
    font-size: 18px;
    height: 5%;
    width: 140px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  img {
    position: fixed;
    left: 0px;
    bottom: 0px;
    height: 5%;
  }
}

@media (max-width: 768px) {
  .Footer {
    button {
      width: 70px;
    }

    &__title {
      font-size: 13px;
    }
    img {
      display: none;
    }
  }
}
