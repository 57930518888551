.Step2 {
  // height: 100%;
  margin: auto;

  .row {
    display: flex;
    justify-content: center;
    align-items: center;
    .comments {
      white-space: pre-wrap;
      //   justify-content:
      text-align: left;
      font-size: 16px;
    }
  }
}

@media only screen and (max-width: 320px) {
  .Step2 {
    .row {
      .comments {
        font-size: 14px;
      }
    }
  }
}
// @media only screen and (max-width: 768px) {
//   .Step2 {
//     // height: 100%;
//     margin: auto;
//     max-width: 90%;

//     .comments {
//       white-space: pre-wrap;
//       //   justify-content:
//       text-align: left;
//     }
//   }
// }

// @media only screen and (min-width: 1024px) {
//   .Step2 {
//     // height: 100%;
//     margin: auto;
//     max-width: 30%;

//     .comments {
//       white-space: pre-wrap;
//       //   justify-content:
//       text-align: left;
//     }
//   }
// }
