.BackButton {
  display: flex;
  position: sticky;
  top: 0;
  right: 0;
  border: 1px solid black;
  background-color: #464646;
  z-index: 1 !important;
  float: right;
  height: 100%;

  &:hover {
    cursor: pointer;
  }
  .section1 {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    width: 85%;
    text-align: center;
  }

  .section2 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15%;
  }
}

@media only screen and (min-width: 768px) {
  .BackButton {
    // height: 5vh;
    width: 219px;
  }
}

@media only screen and (max-width: 768px) {
  .BackButton {
    // height: 10vh;
    width: 140px;
  }
}
