.OrderPlaceHolder {
  height: 100%;

  &__first {
    height: 60%;
    align-items: flex-end;
    display: flex;
    justify-content: center;

    img {
      width: 250px;
      height: 250px;
    }
  }

  &__second {
    height: 40%;

    img {
      margin-top: 60px;
    }
  }

  .vl {
    position: fixed;
    top: 35%;
    left: 50%;
    bottom: 0;
    margin: 0;
    border: none;
    border-left: 2px solid black;
    z-index: 10;
    height: 300px;
  }
}

@media only screen and (max-width: 600px), (max-width: 768px) {
  .OrderPlaceHolder {
    display: none;
  }
}

@media only screen and (max-width: 1024px) {
  .OrderPlaceHolder {
    .vl {
      display: none;
    }
  }
}
