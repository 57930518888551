.Step7 {
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;

  .row {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .message {
      display: flex;
      justify-content: left;
      margin: auto;

      .call_center:hover {
        cursor: pointer;
      }

      svg {
        font-size: 20px;
      }
    }

    img {
      width: 40%;
    }
  }
}

@media only screen and (max-width: 400px) {
  .Step7 {
    .Submit_button_layer {
      margin-top: 0rem !important;
    }
  }
}

@media only screen and (max-width: 600) {
  .Step7 {
    img {
      width: 90% !important;
    }
  }
}
