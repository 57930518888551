.Step5 {
  .row {
    display: flex;
    justify-content: center;

    .step_title {
      p {
        font-size: 24px;
        font-weight: 600;
      }
    }
    form {
      width: 100%;
      text-align: left;
    }
  }
}

@media only screen and (max-width: 768px) {
  .Step5 {
    .row {
      .step_title {
        p {
          font-size: 18px;
          font-weight: 600;
          margin: 0;
        }
      }

      form {
        width: 100%;

        textarea {
          height: 80px;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .Step5 {
    &__container {
      textarea {
        height: 150px;
      }
    }
  }
}
