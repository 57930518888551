.Step4 {
  width: 100%;
  height: 100%;

  .Widget {
    z-index: 1000000;
    display: flex;
    justify-content: center;

    .open-close {
      width: 60px;
      height: 60px;
      border-radius: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      svg {
        width: 25px;
        height: 25px;
        color: #fff;
      }
    }
    .widgetWrapper {
      opacity: 1;
      border-radius: 12px;
      overflow: hidden;
      filter: drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.12));
      margin-bottom: 16px;
      background-color: #fff;
      width: 90%;

      &.scrolled {
        .ChooseLocation {
          padding-top: 160px;
        }
        .BookingStep1 {
          padding-top: 120px;
        }
        .BookingStep2 {
          padding-top: 130px;
        }
        .BookCalendar {
          padding-top: 130px;
        }
        .BookingForm {
          padding-top: 115px;
        }
      }
      .bottomWidgetArea {
        position: fixed;
        bottom: 0;
        width: 100%;
        height: 42px;
        justify-content: center;
        display: flex;
        align-items: center;
        font-size: 12px;
        color: #99acc2;
        background: #ffffff;
        box-shadow: 0px -1px 12px rgba(0, 0, 0, 0.06);
        img {
          margin-left: 5px;
        }
      }
      &.active {
        animation: slide-up-fade-in ease 1s;
        animation-iteration-count: 1;
        transform-origin: 50% 50%;
        animation-fill-mode: forwards; /*when the spec is finished*/
        -webkit-animation: slide-up-fade-in ease 1s;
        -webkit-animation-iteration-count: 1;
        -webkit-transform-origin: 50% 50%;
        -webkit-animation-fill-mode: forwards; /*Chrome 16+, Safari 4+*/
        -moz-animation: slide-up-fade-in ease 1s;
        -moz-animation-iteration-count: 1;
        -moz-transform-origin: 50% 50%;
        -moz-animation-fill-mode: forwards; /*FF 5+*/
        -o-animation: slide-up-fade-in ease 1s;
        -o-animation-iteration-count: 1;
        -o-transform-origin: 50% 50%;
        -o-animation-fill-mode: forwards; /*Not implemented yet*/
        -ms-animation: slide-up-fade-in ease 1s;
        -ms-animation-iteration-count: 1;
        -ms-transform-origin: 50% 50%;
        -ms-animation-fill-mode: forwards; /*IE 10+*/
        opacity: 0;
        opacity: 1\9;
        z-index: 2;
        pointer-events: all;
      }
      &.not-active {
        z-index: -100;
        pointer-events: none;
        animation: slide-down-fade-in ease 1s;
        animation-iteration-count: 1;
        transform-origin: 50% 50%;
        animation-fill-mode: forwards; /*when the spec is finished*/
        -webkit-animation: slide-down-fade-in ease 1s;
        -webkit-animation-iteration-count: 1;
        -webkit-transform-origin: 50% 50%;
        -webkit-animation-fill-mode: forwards; /*Chrome 16+, Safari 4+*/
        -moz-animation: slide-down-fade-in ease 1s;
        -moz-animation-iteration-count: 1;
        -moz-transform-origin: 50% 50%;
        -moz-animation-fill-mode: forwards; /*FF 5+*/
        -o-animation: slide-down-fade-in ease 1s;
        -o-animation-iteration-count: 1;
        -o-transform-origin: 50% 50%;
        -o-animation-fill-mode: forwards; /*Not implemented yet*/
        -ms-animation: slide-down-fade-in ease 1s;
        -ms-animation-iteration-count: 1;
        -ms-transform-origin: 50% 50%;
        -ms-animation-fill-mode: forwards; /*IE 10+*/
      }

      .BookingHeader {
        height: 110px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        transition: 0.5s !important;
        opacity: 0;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 2;
        opacity: 1;
        font-size: 25px;

        p {
          font-size: 22px;
          font-weight: 600;
          margin: 0;
        }
      }
    }

    ::-webkit-scrollbar {
      width: 0; /* Remove scrollbar space */
      background: transparent; /* Optional: just make scrollbar invisible */
    }
    /* Optional: show position indicator in red */
    ::-webkit-scrollbar-thumb {
      background: #ff0000;
    }
  }

  @media (max-width: 768px) {
    .Widget {
      width: 100%;
      height: 500px;
      .open-close {
        position: fixed;
        bottom: 24px;
        right: 24px;
      }
      .widgetWrapper {
        height: 100%;
        border-radius: 0px;
        width: 100%;
        margin: 0;

        .BookingHeader {
          height: 50px;

          p {
            font-size: 18px;
            font-weight: 600;
            margin: 0;
          }
        }
        .ChooseLocation {
          height: 100%;
          width: 100%;
        }
        .BookingStep1 {
          height: calc(100vh - 42px);
          width: 100%;
        }
        .BookingStep2 {
          height: 100%;
          width: 100%;
        }
        .BookCalendar {
          padding-top: 50px;
          height: 100%;
          width: 100%;
        }
        .BookingForm {
          height: calc(100vh - 115px - 42px);
          width: 100%;
        }
        .AllReviews {
          height: calc(100vh - 42px) !important;
          width: 100%;
          .reviewsWrapper {
            height: 100%;
          }
        }
      }
    }
  }

  /* Large devices (laptops/desktops, 992px and up) */
  // @media only screen and (max-width: 768px) {
  //   .Widget {
  //     .widgetWrapper {
  //       height: 100%;
  //     }
  //   }
  // }

  @media (max-width: 576px) {
    .Widget {
      height: 400px;
    }
  }

  @media (max-width: 320px) {
    .Widget {
      height: 350px;
    }
  }

  @keyframes slide-up-fade-in {
    0% {
      opacity: 0;
      transform: translate(0px, 40px);
    }
    100% {
      opacity: 1;
      transform: translate(0px, 0px);
    }
  }

  @-moz-keyframes slide-up-fade-in {
    0% {
      opacity: 0;
      -moz-transform: translate(0px, 40px);
    }
    100% {
      opacity: 1;
      -moz-transform: translate(0px, 0px);
    }
  }

  @-webkit-keyframes slide-up-fade-in {
    0% {
      opacity: 0;
      -webkit-transform: translate(0px, 40px);
    }
    100% {
      opacity: 1;
      -webkit-transform: translate(0px, 0px);
    }
  }

  @-o-keyframes slide-up-fade-in {
    0% {
      opacity: 0;
      -o-transform: translate(0px, 40px);
    }
    100% {
      opacity: 1;
      -o-transform: translate(0px, 0px);
    }
  }

  @-ms-keyframes slide-up-fade-in {
    0% {
      opacity: 0;
      -ms-transform: translate(0px, 40px);
    }
    100% {
      opacity: 1;
      -ms-transform: translate(0px, 0px);
    }
  }

  //slide-down

  @keyframes slide-down-fade-in {
    0% {
      opacity: 1;
      transform: translate(0px, 0px);
    }
    100% {
      opacity: 0;
      transform: translate(0px, 40px);
    }
  }

  @-moz-keyframes slide-down-fade-in {
    0% {
      opacity: 1;
      -moz-transform: translate(0px, 0px);
    }
    100% {
      opacity: 0;
      -moz-transform: translate(0px, 40px);
    }
  }

  @-webkit-keyframes slide-down-fade-in {
    0% {
      opacity: 1;
      -webkit-transform: translate(0px, 0px);
    }
    100% {
      opacity: 0;
      -webkit-transform: translate(0px, 40px);
    }
  }

  @-o-keyframes slide-down-fade-in {
    0% {
      opacity: 1;
      -o-transform: translate(0px, 0px);
    }
    100% {
      opacity: 0;
      -o-transform: translate(0px, 40px);
    }
  }

  @-ms-keyframes slide-down-fade-in {
    0% {
      opacity: 1;
      -ms-transform: translate(0px, 0px);
    }
    100% {
      opacity: 0;
      -ms-transform: translate(0px, 40px);
    }
  }

  @keyframes slide-up2-fade-in {
    0% {
      opacity: 0;
      transform: translate(0px, 500px);
    }
    100% {
      opacity: 1;
      transform: translate(0px, 0px);
    }
  }
}
