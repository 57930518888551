.Step3 {
  .row {
    display: flex;
    justify-content: center;

    form {
      width: 100%;

      .search-input {
        padding: 0px;
        /* height: 52px; */
        height: calc(1.5em + 0.75rem + 2px);
        position: relative;
        // border: 1px solid #ced4da;
        border-radius: 0.25rem;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        margin-bottom: 1rem;
        // margin-top: 1.5rem;
      }

      .search-input::before {
        content: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAYAAAA71pVKAAAAAXNSR0IArs4c6QAAAQJJREFUKBWVkr2uQUEUhf3ET6GRaC5aFRoJKrf1BKpb8SwqovYGXkCj00k0QnRKEYkILYobvpUYmeMMyVnJl7P3mjN7Zu9zwiGv2qRFyMMSRrAFp6JPN8XzBj+wgDkUYAg7WINTYdwpDECxrRLJHeq2accdkgm8bzTvNAg2EDOGeUYI1KNO1gkuzTA1g8T7ojbn4ONQWPuHPWgeHmnzCqoe15tkSNPgPEAn68oVcOmA2XMtGK9FoE/VhOTTVNExqLCGZnxCv2pYauEC6lF0oQxX6IOvb7yX9NPEQafan+aPXDdQC18LsO6Tip5BBY6gIQaSbnMCFRCBZRcIvFkbsvCr4AFGOCxQy+JdGQAAAABJRU5ErkJggg==");
        display: block;
        position: absolute;
        width: 15px;
        z-index: 3;
        height: 15px;
        font-size: 20px;
        top: 5px;
        left: 16px;
        line-height: 32px;
        opacity: 0.6;
      }

      .search-input > input {
        display: block;
        width: 100%;
        height: calc(1.5em + 0.75rem + 2px);
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        padding: 0px 0px 0px 39px;
      }

      .search-input > input:focus {
        outline: none;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .Step3 {
    .auto-detect-location {
      display: none !important;
    }
  }
}
