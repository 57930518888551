.LocationPicker {
  width: 80%;
  &__opened {
    cursor: pointer;
    display: inline-flex;
    width: 70%;
    align-items: center;
    justify-content: center;
  }

  &__closed {
    cursor: pointer;
    display: inline-flex;
    width: 70%;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #e7e7e7;
  }
}
