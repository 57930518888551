.Step6 {
  height: 100%;
  display: flex;
  align-items: center;

  .row {
    display: flex;
    justify-content: center;

    .title {
      margin-top: 1.5rem;
      .title_text {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  &__orderInfo {
    &__item {
      display: flex;
      align-items: center;
      background-color: #f5f5f5;
      height: 3rem;

      &__value {
        justify-content: center;
      }
    }
  }

  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-border-radius: 10px;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: #464646;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  }
}

@media (max-width: 768px) {
  .swal2-title {
    font-size: 20px;
  }
  .swal2-title {
    text-align: left;
  }
}

@media (min-width: 768px) {
  .swal2-title {
    display: flex !important;
    justify-content: center;
    font-size: 25px;
  }
  .swal2-title {
    display: flex !important;
    justify-content: center;
    text-align: left;
  }
}

@media (max-width: 768px) {
  .Step6 {
    .title {
      margin-top: 0.25rem !important;
    }

    &__orderInfo {
      &__item {
        margin-top: 0.25rem !important;
      }
    }
  }
}

@media only screen and (max-width: 320px) {
  .Step6 {
    &__orderInfo {
      &__item {
        height: 2.6rem;
      }
    }
  }
}
