.modal-dialog {
  .modal-header {
    .modal-title {
      // width: 100%;
      height: 100%;
      margin: auto;
      justify-content: center;
      align-items: center;
      display: flex;
    }
    img {
      height: 50px;
    }
  }

  .modal-body {
    overflow-y: scroll;
  }
  table {
    text-align: center;
    vertical-align: middle;

    tbody {
      tr {
        td {
          vertical-align: middle;
        }
      }
    }
  }
}

@media (max-width: 320px) {
  .modal-dialog {
    .modal-header {
      .modal-title {
        p {
          font-size: 14px !important;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .modal-dialog {
    .modal-header {
      .modal-title {
        p {
          font-size: 17px;
        }
      }
    }

    .modal-content {
      height: 400px;
    }
  }
}

@media (min-width: 768px) {
  .modal-dialog {
    .modal-content {
      height: 400px;
    }
  }
}
